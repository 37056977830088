<template>
  <div id="reference-parts-sidebar-wrap" class="d-flex" :style="style">
    <div id="reference-parts-sidebar" v-show="open">
      <div class="header py-3">
        <div class="d-flex justify-end">
          <v-btn icon @click="onClickFullExpand" color="blue darken-4">
            <v-icon v-show="!fullExpand">mdi-chevron-double-right</v-icon>
            <v-icon v-show="fullExpand">mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>

        <div class="sp-common-width-narrow px-8 mx-auto text-left">
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="btCancel"
                  v-bind="attrs"
                  v-on="on"
                >
                  参照する戦略パーツを選択
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="section in sections"
                  :key="section.sectionId"
                  @click="currentSection = section"
                  dense
                >
                  <v-list-item-title>{{ section.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      
      <div class="parts-wrap px-8 py-4">
        <div
          :class="
            'parts d-flex ' + (fullExpand ? 'justify-center' : 'justify-start')
          "
        >
          <section-resolver
            v-if="dataInit"
            :elementId="currentSection.dataRef"
            :name="strategyPartsMetaDict[currentSection.dataRef].comp"
            :inputStatus="currentSection.inputStatus"
            :ref="currentSection.name"
            :hasWritePermission="false"
            :dataInit="dataInit"
            ref-mode
            :ref-full-expand="fullExpand"
            v-model="value[this.partsMeta.inputDataKey]"
            @update="needUpdate = true"
          ></section-resolver>
        </div>
      </div>
    </div>
    <div class="open-btn" v-ripple @click="onClickOpen">
      <v-icon color="white" class="pb-2">mdi-file-eye-outline</v-icon>
      戦略パーツを見る
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import strategyPartsMetaDict, {
  StrategyPartsMetaDictItem,
} from "../../../utils/strategyPartsMetaDict";
import SectionResolver from "@/components/util/SectionResolver";

export default {
  components: {
    SectionResolver,
  },
  data() {
    return {
      open: false,
      fullExpand: false,
      dataInit: false,
      currentSection: null,
      strategyPartsMetaDict,
      value: null,
      partsMeta: new StrategyPartsMetaDictItem(),
    };
  },
  computed: {
    ...mapGetters(["registeredSections", "hasWritePermission"]),
    style() {
      if (this.fullExpand & this.open) {
        return { width: "100%", minWidth: "none" };
      }
      if (this.open) {
        return { width: "600px", minWidth: "600px" };
      }
      return { width: 0, minWidth: 0 };
    },
    sections() {
      return this.registeredSections?.sections.map((section) => {
        return { ...section.section, inputStatus: section.inputStatus };
      });
    },
  },
  async created() {
    // セクションの一覧を取得
    if (!this.registeredSections) {
      await this.getRegisteredSections();
    }
  },
  methods: {
    ...mapActions(["getRegisteredSections"]),

    onClickFullExpand() {
      this.fullExpand = !this.fullExpand;
      const val = this.fullExpand & this.open;
      this.$emit("click-expand", val);
    },
    onClickOpen() {
      this.open = !this.open;
      const val = this.fullExpand & this.open;
      this.$emit("click-expand", val);
    },
  },
  watch: {
    async currentSection(to) {
      if (!to) return;

      // メタ情報辞書から戦略パーツメタ情報を取得する
      this.partsMeta = strategyPartsMetaDict[to.dataRef];

      // データを取得する
      await this.$store.dispatch(this.partsMeta.fetchAction);
      this.value = this.$store.getters[this.partsMeta.getterKey];
      this.dataInit = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#reference-parts-sidebar-wrap {
  position: sticky;
  top: 0;
  height: calc(100vh - 125px);
  z-index: 100;
  transition: width 300ms 0s ease;
  transition-property: width, min-width, max-width;
  #reference-parts-sidebar {
    max-width: calc(100% - 50px);
    min-width: calc(100% - 50px);
    background-color: #f1f7ff;
    border-left: solid 6px $bt-primary;
    border-right: solid 6px $bt-primary;
    display: flex;
    flex-direction: column;
    .header {
      position: sticky;
      top: 0;
      left: 0;
      background-color: #f1f7ff;
      box-shadow: 0px 2px 2px 0px #bdbdbd;
    }
    .parts-wrap {
      width: 100%;
      height: 100%;
      overflow-x: auto;
      overflow-y: auto;

      @include scrollAreaY();
      @include scrollAreaX();
    }
  }
  .open-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: #0761b4;
    color: white;
    width: 50px;
    height: fit-content;
    padding: 10px;
    margin-top: 100px;
    border-radius: 0 15px 15px 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
#reference-parts-sidebar-wrap {
  .parts-chips {
    .v-slide-group__content {
      justify-content: center !important;
    }
  }
  .parts {
    .ref-mode {
      // 参照エリア見た目調整用
      .v-text-field--outlined {
        fieldset {
          // 外枠非表示
          border: none;
        }
        .v-label--active {
          // ラベルの位置調整
          color: black;
          transform: translateY(-40px) translateX(-12px) scale(0.75);
        }
        .v-label--active::before {
          // ラベル行頭に「・」表示
          content: "・";
        }
        .v-input__slot {
          margin-top: 20px !important;
          background-color: #eceff1 !important;
        }
        .v-input__append-inner {
          // セレクトボックスの▼を非表示
          display: none;
        }
      }
    }
  }
}
</style>